<template>
  <div>
    <div class="custom-container">
      <b-row>
        <b-col sm="12">
          <card class="p-0">
            <template class="p-0" v-slot:headerTitle>
              <div class="d-flex flex-column align-items-start">
                <h3 class="card-title p-0">
                  <b>Honorarios</b>
                </h3>
              </div>
            </template>
            <div>
              <div class="ml-3 mt-1" v-if="dataProfesional != ''">
                <h4>{{ dataProfesional + " " + (PorcentajeSucursal > 0 ? PorcentajeSucursal + "%" : "") }}</h4>
              </div>
              <b-button v-if="profesional" variant="success" class="float-right mt-3 mr-5" @click="exportToExcel">Exportar a Excel</b-button>
              <b-button v-if="profesional && existeHonorarios == 0" variant="info" class="float-right mt-3 mr-3" @click="liquidar()">Liquidar</b-button>
              <b-button v-if="profesional" variant="info" class="float-right mt-3 mr-3" @click="verResumen()">Ver Resumen</b-button>
              <!--<b-button variant="info" class="float-right mt-3 mr-3" @click="verLaboratorioCostospordefecto">Laboratorio Valores por defecto</b-button> -->
              <b-button variant="info" class="float-right mt-3 mr-3" @click="verCostospordefecto">Mat. Esp. Valores por defecto</b-button>
            </div>
            <div class="px-5 py-3">
              <!-- <div class="row" style="gap: 1rem">
                <div class="col-12 px-0">
                  <CardTable :data="dataCards" />
                </div>
                <div class="col-12 px-0">
                  <CardTable :data="dataCards2" />
                </div>
              </div> -->
              <!-- <div class="row" style="gap: 1rem">
                <div class="col-6 ">
                 
                  <CardTable :data="dataCards" />
                </div>
                <div class="col-6  ">
                  
                  <CardTable :data="dataCards2" />
                </div>
                 
              </div> -->
            </div>
            <div class="row px-3 ml-1 mb-4">
              <div class="col-2 px-0">
                <h4 class="mb-2"><b>Mes:</b></h4>
                <b-select v-model="mesSeleccionado" class="form-control bg-dark personalizado" :options="listaMes" text-field="mes" value-field="id" />
              </div>
              <div class="col-2 px-1">
                <h4 class="mb-2"><b>Año:</b></h4>
                <b-select v-model="anioSeleccionado" class="form-control bg-dark personalizado" :options="listaAnios" />
              </div>
              <!-- <div class="col-1 px-0 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(1)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div> -->
              <!-- <div class="col px-1" v-if="listaItemsbk.length > 0">
                <h4 class="mb-2"><b>Filtrar x paciente:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="paciente"
                  :options="listaPacientes"
                  label="pacienteNombre"
                  placeholder="-- selecciona un paciente --"
                ></v-select>
              </div> -->
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x profesional:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="profesional"
                  :options="listaProfesional"
                  label="nombreProfesional"
                  placeholder="-- selecciona un profesional --"
                  @input="seleccionaProfesional()"
                ></v-select>
              </div>
              <div class="col px-1">
                <h4 class="mb-2"><b>Filtrar x sucursal:</b></h4>
                <v-select
                  required
                  class="style-chooser"
                  :reduce="(value) => value"
                  v-model="sucursal"
                  :options="listaSucursal"
                  label="sucursal"
                  placeholder="-- selecciona opc. --"
                  @input="seleccionaSucursal()"
                ></v-select>
              </div>
              <div class="col-1 px-1 mt-3">
                <vs-button primary class="px-2 py-1" @click="filtrar(2)" id="sendform2">
                  <i class="fas fa-search mr-1"></i>
                </vs-button>
              </div>
            </div>
            <div>
              <div v-if="1 == 1">
                <h5 class="text-center mb-2">
                  <b class="text-danger">{{ existeHonorarios > 0 ? "Honorarios ya liquidados" : "" }}</b>
                </h5>
                <!-- table registro servicios-->
                <div id="ttos" class="px-3 mt-1">
                  <h4 class="card-title px-3">
                    <b
                      >Ttos. realizados, cancelados y evolucionados:
                      {{
                        listaItems.length +
                        " / " +
                        totalTtosRealizados.toLocaleString("es-CO", {
                          style: "currency",
                          currency: "COP",
                        })
                      }}</b
                    >
                  </h4>
                  <dataTableButton
                    :data="listaItems"
                    :headers="encabezadostablaJson[0]"
                    :items-per-page="itemsPerPage"
                    :actions1="modalTratamiento"
                    :actions2="modalValueExcluido"
                    button2="fa-regular fa-eye-slash"
                    titleButton2="Excluir valor"
                    color2="danger"
                    button1="fa-solid fa-wrench"
                    titleButton1="Editar valor tratamiento"
                    color="success"
                  />
                </div>
                <!-- tabla costos material especial -->

                <div id="mespecial" class="px-3 mt-3">
                  <h4 class="card-title px-3">
                    <b>Costos material especial: {{ listaItemsMaterial.length + " / " + totalCostosMaterial.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</b>
                  </h4>
                  <!-- <data-table :data="listaItemsMaterial" :headers="encabezadostablaJson[1]" :items-per-page="itemsPerPage" /> -->
                  <dataTableButton
                    :data="listaItemsMaterial"
                    :headers="encabezadostablaJson[1]"
                    :items-per-page="itemsPerPage"
                    :actions2="modalValueExcluido"
                    button2="fa-regular fa-eye-slash"
                    titleButton2="Excluir"
                    color2="danger"
                  />
                </div>
                <!-- costos de remisión -->
                <div id="cremision" class="px-3 mt-3">
                  <h4 class="card-title px-3">
                    <b
                      >Costos de remisión:
                      {{
                        listaItemsRemision.length +
                        " / " +
                        totalCostosRemision.toLocaleString("es-CO", {
                          style: "currency",
                          currency: "COP",
                        })
                      }}</b
                    >
                  </h4>
                  <data-table :data="listaItemsRemision" :headers="encabezadostablaJson[1]" :items-per-page="itemsPerPage" />
                </div>

                <!-- tabla costos laboratorio -->
                <div id="claboratorio" class="px-3 mt-3">
                  <h4 class="card-title px-3">
                    <b
                      >Costos de laboratorio:
                      {{
                        listaLaboratorio.length +
                        " / " +
                        totalCostosLaboratorio.toLocaleString("es-CO", {
                          style: "currency",
                          currency: "COP",
                        })
                      }}</b
                    >
                  </h4>
                  <!-- {{ listaLaboratorio }} -->
                  <dataTableButton
                    :data="listaLaboratorio"
                    :headers="encabezadostablaJson[3]"
                    :items-per-page="itemsPerPage"
                    :actions1="modalLaboratorio"
                    button1="fa-solid fa-wrench"
                    titleButton1="Ajustar Valor"
                    :actions2="modalValueExcluido"
                    button2="fa-regular fa-eye-slash"
                    titleButton2="Excluir valor"
                    color2="danger"
                  />
                </div>

                <!-- tabla otros -->

                <div class="px-3 mt-3">
                  <div class="px-3 mt-3 d-flex">
                    <h4 class="card-title pt-2">
                      <b>Otros conceptos: </b>
                    </h4>
                    <div class="ml-3">
                      <vs-button v-if="existeHonorarios == 0" primary class="" @click="verModal"> Nuevo registro </vs-button>
                    </div>
                  </div>
                  <!-- {{ otrosConceptos }} -->
                  <!-- <data-table :data="otrosConceptos" :headers="encabezadostablaJson[2]" :items-per-page="itemsPerPage" /> -->
                  <dataTableButton
                    :data="otrosConceptos"
                    :headers="encabezadostablaJson[2]"
                    :items-per-page="itemsPerPage"
                    :actions1="EliminarOtrosConceptos"
                    button1="fa-solid fa-trash"
                    titleButton1="Eliminar"
                    color="danger"
                    color2="success"
                  />
                </div>
                <!-- items excluidos -->
                <div id="mespecial" class="px-3 mt-5">
                  <h4 class="card-title px-3">
                    <b>Observaciones:</b>
                  </h4>
                  <!-- <data-table :data="itemsExcluidos" :headers="encabezadostablaJson[4]" :items-per-page="itemsPerPage" /> -->
                  <dataTableButton
                    :data="itemsExcluidos"
                    :headers="encabezadostablaJson[4]"
                    :items-per-page="itemsPerPage"
                    :actions2="restaurarItem"
                    button2="fa-solid fa-wrench"
                    titleButton2="Restaurar"
                    color2="success"
                  />
                </div>
              </div>
            </div>
            <!-- modal add servicio -->
            <b-modal no-enforce-focus title="Nuevo Registro" id="nuevoServicio" size="lg" hide-footer body-class="body-modal-custom">
              <b-form @submit.prevent="saveData()">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Sucursal</label>
                      <v-select
                        disabled
                        required
                        class="style-chooser"
                        :reduce="(value) => value"
                        v-model="sucursal"
                        :options="listaSucursal"
                        label="profesionalPresupuesto"
                        placeholder="-- selecciona opc. --"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Profesional</label>
                      <v-select
                        required
                        disabled
                        class="style-chooser"
                        :reduce="(value) => value"
                        v-model="profesional"
                        :options="listaProfesional"
                        label="profesionalPresupuesto"
                        placeholder="-- selecciona un profesional --"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Paciente</label>
                      <!-- <b-form-select required value-field="id" text-field="descripcion" v-model="persona.TipoDocumentoId" :options="listaTipoDocumento"></b-form-select> -->
                      <div class="row">
                        <div class="col-md-10">
                          <v-select
                            v-if="pacienteManual == ''"
                            required
                            class="style-chooser"
                            :reduce="(value) => value"
                            :get-option-label="(option) => `${option.paciente}`"
                            v-model="paciente"
                            :options="listaPacientes"
                            label="pacienteNombre"
                            placeholder="-- selecciona un paciente --"
                            @input="seleccionarPaciente()"
                          ></v-select>
                          <input class="form-control" type="text" name="" v-if="pacienteManual != ''" v-model="pacienteManual" id="" />
                        </div>
                        <div class="col-md-1">
                          <b-button @click="ingresarPaciente()" variant="info" class="mt-0 float-left">+</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Identificación Paciente</label>
                      <input required v-model="Registro.Rut" placeholder="" type="text" class="form-control" id=" " />

                      <!-- <b-form-select required value-field="id" text-field="descripcion" v-model="persona.TipoDocumentoId" :options="listaTipoDocumento"></b-form-select> -->
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">rut paciente</label>
                      <input required v-model="persona.NumeroDocumento" placeholder="Escriba el codigo..." type="text" class="form-control" id=" " />
                    </div>
                  </div> -->
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Concepto</label>
                      <input required v-model="Registro.Concepto" placeholder="Ej: TTOS REALIZADOS NO CANCELADOS POR EL PACIENTE" type="text" class="form-control" id=" " />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Descripción</label>
                      <input required v-model="Registro.Descripcion" placeholder="Ej: INJERTO GINGIVAL LIBRE - 30.o" type="text" class="form-control" id=" " />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for=" " class="form-label">Valor</label>
                      <input required v-model="Registro.Valor" placeholder="Escriba el valor..." type="number" class="form-control" id=" " />
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <label for=" " class="form-label">Tipo </label>
                      <b-form-select required value-field="descripcion" text-field="descripcion" v-model="Registro.Tipo" :options="listaTipo"></b-form-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div>
                      <!-- <label for=" " class="form-label">Tipo </label> -->
                      <b-form-checkbox class="mt-3" size="sm" v-model="Registro.ExcluirPorcentaje">Excluir del porcentaje</b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
                  <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.hide('nuevoServicio')">Cancelar</button>
                </div>
              </b-form>
              <!-- <add_servicio :sharedService="sharedService" /> -->
            </b-modal>
            <b-modal no-enforce-focus :title="'Resumen: ' + cadenaMes() + ' ' + anioSeleccionado" id="resumen" size="lg" hide-footer body-class="body-modal-custom">
              <div class="p-2 mb-3 text-center">
                <span class="h5"> {{ profesional != null ? profesional : "" }} </span>
              </div>

              <div class="row px-3">
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Ttos. realizados, cancelados y evol. :</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalTtosRealizados.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Costos material especial</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalCostosMaterial.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Costos de remisión</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalCostosRemision.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Costos de laboratorio</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalCostosLaboratorio.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Total agregados</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalAgregados.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Total descuentos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalDescuentos.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>TOTAL TTOS REALIZADOS, CANCELADOS Y EVOLUCIONADOS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ totalNetoHonorarios.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>{{ `VALOR NETO HONORARIOS (${PorcentajeSucursal}%)` }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{ TotalHonorarios.toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-modal>
            <b-modal no-enforce-focus title="Valores por defecto" id="costospordefecto" size="lg" hide-footer body-class="body-modal-custom">
              <!-- <div class="p-2 mb-3 text-center">
                <span class="h5"> {{ profesional != null ? profesional : "" }} </span>
              </div> -->

              <div class="row px-3">
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>COSTOS DE MATERIAL ESPECIAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input v-if="CostosPorDefecto[0].valorPorDefecto" v-model="CostosPorDefecto[0].valorPorDefecto" type="number" name="" id="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-6 px-1">
                  <table id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>COSTOS DE REMISION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <input v-if="CostosPorDefecto[1].valorPorDefecto" v-model="CostosPorDefecto[1].valorPorDefecto" type="number" name="" id="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <b-button variant="danger" class="float-right mt-3 mr-2" @click="$bvModal.hide('costospordefecto')">Cerrar</b-button>
                <b-button variant="info" class="float-right mt-3 mr-2" @click="GuardarCamposValoresPorDefecto()">Guardar cambios</b-button>
              </div>
            </b-modal>
            <!-- laboratorio costos por defecto -->
            <b-modal no-enforce-focus title="Laboratorio Valores por defecto" id="LaboratorioCostospordefecto" size="lg" hide-footer body-class="body-modal-custom">
              <div class="row px-3">
                <div class="col-12 px-1">
                  <table v-if="LaboratorioCostosPorDefecto.length > 0" id="datatable_registroServicios" class="table table-bordered table-responsive-md table-striped text-center">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="laboratorioCostoItem of LaboratorioCostosPorDefecto" :key="laboratorioCostoItem.id">
                        <td>
                          {{ laboratorioCostoItem.descripcion }}
                        </td>
                        <td>
                          <input v-if="LaboratorioCostosPorDefecto.length > 0" v-model="laboratorioCostoItem.valorPorDefecto" type="number" name="" id="" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <b-button variant="danger" class="float-right mt-3 mr-2" @click="$bvModal.hide('LaboratorioCostospordefecto')">Cerrar</b-button>
                <b-button variant="info" class="float-right mt-3 mr-2" @click="GuardarCamposValoresPorDefecto()">Guardar cambios</b-button>
              </div>
            </b-modal>
          </card>
        </b-col>
      </b-row>
    </div>
    <!-- MODAL AJUSTE VALORES TRATAMIENTO -->
    <b-modal size="sm" centered v-model="showModalTratamiento" title="Cambiar valor" hide-footer id="custom-modal" class="custom-modal">
      <form @submit.prevent="submitNewValueTratamiento">
        <div class="form-group">
          <label for="ajustarValor">Ajustar valor:</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="text" class="form-control" id="ajustarValor" v-model="newValueTratamiento" placeholder="0.00" required />
          </div>
        </div>
        <div class="text-center mt-3">
          <b-button type="submit" variant="primary">Guardar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL AJUSTE VALORES LABORATORIO-->
    <b-modal size="sm" centered v-model="showModalLaboratorio" title="Cambiar valor" hide-footer id="custom-modal" class="custom-modal">
      <form @submit.prevent="submitNewValueLab">
        <div class="form-group">
          <label for="ajustarValor">Ajustar valor:</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">$</span>
            </div>
            <input type="text" class="form-control" id="ajustarValor" v-model="newValueLaboratorio" placeholder="0.00" required />
          </div>
        </div>
        <div class="text-center mt-3">
          <b-button type="submit" variant="primary">Guardar</b-button>
        </div>
      </form>
    </b-modal>
    <!-- MODAL AJUSTE VALORES EXCLUIDO-->
    <b-modal size="md" centered v-model="showModalValorExcluido" title="Excluir valor" hide-footer id="custom-modal" class="custom-modal">
      <form @submit.prevent="submitNewValueExcluido" class="p-2">
        <div class="form-group mb-4">
          <label for="observacion" class="font-weight-bold mb-2">Observación:</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-comment-alt"></i>
              </span>
            </div>
            <input type="hidden" class="form-control" id="ajustarValor" v-model="newValueExcluido" placeholder="0.00" required />
            <input type="text" class="form-control" id="observacion" v-model="observacionValorExcluido" placeholder="Ingrese una observación" />
          </div>
        </div>

        <div class="text-center mt-4">
          <b-button type="submit" variant="primary" class="px-4 py-2"> <i class="fas fa-save mr-2"></i>Guardar </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import dataTable from "./TablaComponente.vue";
import dataTableButton from "./TablaComponenteButton.vue";
// import CardTable from "@/components/cardTable/CardTableV3.vue";
import * as XLSX from "xlsx";

const Swal = require("sweetalert2");
export default {
  name: "registroServicios",
  components: {
    dataTable,
    dataTableButton,
  },
  data() {
    return {
      listaMes: [
        { id: 1, mes: "Enero" },
        { id: 2, mes: "Febrero" },
        { id: 3, mes: "Marzo" },
        { id: 4, mes: "Abril" },
        { id: 5, mes: "Mayo" },
        { id: 6, mes: "Junio" },
        { id: 7, mes: "Julio" },
        { id: 8, mes: "Agosto" },
        { id: 9, mes: "Septiembre" },
        { id: 10, mes: "Octubre" },
        { id: 11, mes: "Noviembre" },
        { id: 12, mes: "Diciembre" },
      ],
      listaAnios: [],
      mesSeleccionado: null,
      anioSeleccionado: null,
      persona: {},
      dataTableInstance: null,
      listaPacientes: [],
      listaProfesional: [],
      listaProfesionalBk: [],

      listaSucursal: [],
      listaItems: [],
      listaItemsbk: [],
      paciente: null,
      profesional: null,
      sucursal: null,
      listaItemsMaterial: [],
      listaLaboratorio: [],
      listaLaboratorioBk: [],
      listaItemsRemision: [],
      listaTipo: [{ descripcion: "Descuento" }, { descripcion: "Agregado" }],
      Registro: {},
      Periodo: {},
      otrosConceptos: [],
      pacienteManual: "",
      totalAgregados: 0,
      totalDescuentos: 0,
      totalTtosRealizados: 0,
      totalCostosMaterial: 0,
      totalCostosRemision: 0,
      totalCostosLaboratorio: 0,
      totalNetoHonorarios: 0,
      activaTablas: false,
      existeHonorarios: 0,
      //////////////*css*/`
      itemsPerPage: 5,
      currentPages: [1, 1, 1, 1, 1], // Una página para cada tabla

      encabezadostablaJson: [
        //ttos realizados
        [
          { header: "Identificación Paciente", key: "rut" },
          { header: "Paciente nombre", key: "pacienteNombre" },
          { header: "Prestación", key: "prestacion" },
          { header: "Fecha", key: "fechaAccion" },
          { header: "Valor original", key: "valorPrestacion" },
          { header: "Valor Ajustado", key: "valorTratamientoAjustado" },
          { header: "Porcentaje", key: "porcentaje" },
          { header: "Valor subvencionado", key: "valorMenosSubvencion" },
        ],
        //material especial y costos remision
        [
          // { header: "materialId", key: "materialId" },
          { header: "Identificación Paciente", key: "idPaciente" },
          { header: "Paciente nombre", key: "paciente" },
          { header: "Prestación", key: "prestacionArea" },
          { header: "Valor", key: "valorPrestacion" },
          { header: "Fecha Acción", key: "fecha" },
        ],
        //otros conceptos
        [
          { header: "Identificación Paciente", key: "rut" },
          { header: "Paciente nombre", key: "pacienteNombre" },
          { header: "Concepto", key: "concepto" },
          { header: "Descripción", key: "descripcion" },
          { header: "Excluido del porcentaje", key: "excluirPorcentaje" },
          { header: "Tipo", key: "tipo" },
          { header: "Valor", key: "valor" },
        ],
        //laboratorio
        [
          { header: "Identificación Paciente", key: "rut" },
          { header: "Paciente nombre", key: "pacienteNombre" },
          { header: "Prestación", key: "prestacion" },
          { header: "Valor original", key: "valorPrestacion" },
          { header: "Valor ajustado", key: "valorPrestacionAjustado" },
          { header: "Fecha Acción", key: "fechaAccion" },
        ],
        //excluidos
        [
          { header: "Identificación Paciente", key: "rut" },
          { header: "Paciente nombre", key: "pacienteNombre" },
          { header: "Prestación", key: "prestacion" },
          { header: "Valor", key: "valorPrestacion" },
          { header: "Fecha Acción", key: "fFechaAccion" },
          { header: "Observación", key: "observacionValorExcluido" },
        ],
      ],
      PorcentajeSucursal: 0,
      TotalHonorarios: 0,
      CostosPorDefecto: [],

      // ATRIBUTOS PARA ACTUALIZACIÓN VALOR ,ValorExcluido Y	ObservacionValorExcluido
      showModalLaboratorio: false,
      modalLaboratorioSeleted: "",
      newValueLaboratorio: "",

      showModalValorExcluido: false,
      modalValorExcluidoSelected: "",
      newValueExcluido: "Excluido",
      observacionValorExcluido: "",

      showModalTratamiento: false,
      modalTratamientoSeleted: "",
      newValueTratamiento: "",
      //------------------------------------------------------------------------------//
      LaboratorioCostosPorDefecto: [],
      itemsExcluidos: [],
      rutProfesional: "",
      dataProfesional: "",
    };
  },
  async mounted() {
    const anioActual = new Date().getFullYear();
    const anioInicio = anioActual - 2;
    const listaAnios = [];

    for (let anio = anioInicio; anio <= anioActual; anio++) {
      listaAnios.push(anio);
    }
    this.listaAnios = listaAnios;
    // this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.listaItems = [];
    this.mesSeleccionado = new Date().getMonth();
    this.anioSeleccionado = new Date().getFullYear();
    this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(this.anioSeleccionado, this.mesSeleccionado);
    // core.index();
    this.getProfesionalesYSucursales();
    this.obtenerCostosPorDefecto();
    // this.obtenerLaboratorioCostosPorDefecto();
  },
  methods: {
    restaurarItem(id) {
      try {
        Swal.fire({
          title: "¿Quieres revertir?",
          text: "Con esta opcion revertirás la exclusión, ¿deseas continuar?",
          icon: "warning",
          showCancelButton: true,
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            this.$isLoading(true);
            //ingresando ttos realizados etc
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/RevertirExclusion/" + id,
            });
            // await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
            this.calculoTotales();
            this.$isLoading(false);
            Swal.fire("Muy Bien!", "Reversión realizada correctamente!", "success").then(() => {});
          }
        });
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    seleccionarPaciente() {
      this.Registro.Rut = this.paciente.Rut;
      this.Registro.PacienteNombre = this.paciente.paciente;
      console.log("paciente", this.Registro);
    },
    modalEditarOtrosConceptos() {},
    EliminarOtrosConceptos(id) {
      console.log(id);
      try {
        Swal.fire({
          title: "¿Quieres Eliminar?",
          text: "Después de eliminar no podrás recuperar los datos!",
          icon: "warning",
          showCancelButton: true,
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            this.$isLoading(true);
            //ingresando ttos realizados etc
            await this.$store.dispatch("denti_delete", {
              path: "Prestaciones/EliminarOtrosConceptos/" + id,
            });
            await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
            this.calculoTotales();
            this.$isLoading(false);
            Swal.fire("Muy Bien!", "Concepto eliminado correctamente!", "success").then(() => {});
          }
        });
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async obtenerDataProfesional() {
      // this.dataProfesional = "";
      // this.PorcentajeSucursal = 0;
      let Persona = await this.$store.dispatch("hl_get", {
        path: "Persona/GetPersonaPorDocumento/" + this.rutProfesional,
      });
      if (Object.prototype.hasOwnProperty.call(Persona, "id")) {
        let dataProfesional = await this.$store.dispatch("hl_get", {
          path: "FormalEducation/GetByPersonaId/" + Persona.id + "/" + Persona.empresaId,
        });
        console.log("persona-->", dataProfesional);
        if (dataProfesional.length > 0) {
          this.dataProfesional = dataProfesional[0].degree;
          //ahora si obtener el porcentaje de acuerdo a dataprofesional
        }
      } else {
        this.dataProfesional = "";
        this.PorcentajeSucursal = 0;
        this.dataProfesional = "Ficha del profesional no encontrada en Personas";
      }
    },
    async seleccionaProfesional() {
      this.rutProfesional = this.profesional.rut;
      this.profesional = this.profesional.nombreProfesional;
      this.sucursal = null;
      this.PorcentajeSucursal = 0;

      await this.obtenerDataProfesional();
    },
    async seleccionaSucursal() {
      this.sucursal = this.sucursal.sucursal;
      await this.obtenerDataProfesional();
      await this.obtenerPorcentajeSucursal();
    },
    async obtenerPorcentajeSucursal() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ObtenerPorcentajeSucursal/" + this.sucursal + "/" + this.dataProfesional,
        });
        this.PorcentajeSucursal = res;
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.PorcentajeSucursal = 0;
      }
    },
    GuardarCamposValoresPorDefecto() {},
    async obtenerLaboratorioCostosPorDefecto() {
      try {
        let res = await this.$store.dispatch("denti_get", {
          path: "SIE_Laboratorio/ListaLaboratorioCostosPorDefecto",
        });
        this.LaboratorioCostosPorDefecto = res;
        console.log("this.CostosPorDefecto", this.LaboratorioCostosPorDefecto);
      } catch (error) {
        this.CostosPorDefecto = [];
      }
    },
    async obtenerCostosPorDefecto() {
      try {
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ObtenerCostosPorDefecto",
        });
        this.CostosPorDefecto = res;
        console.log("this.CostosPorDefecto", this.CostosPorDefecto);
      } catch (error) {
        this.CostosPorDefecto = [];
      }
    },
    async getProfesionalesYSucursales() {
      try {
        // this.listaItems = [];
        this.$isLoading(true);
        let res = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaProfesionales/",
        });
        // this.listaProfesional = res;
        this.listaProfesionalBk = [...res];

        // this.listaProfesional = await res.map((x) => {
        //   console.log("x-->0", x);
        //   let nombreProfesional = null;
        //   if (x.nombreProfesional != null && x.nombreProfesional.length > 0) {
        //     let resulta = x.nombreProfesional.split(",");
        //     const normalize = (nombre) => nombre.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
        //     nombreProfesional = resulta.length > 1 ? normalize(resulta[1]) + " " + normalize(resulta[0]) : resulta[0];
        //     console.log("x-->1", nombreProfesional);
        //   }
        //   return { nombreProfesional: nombreProfesional, rut: x.idPaciente };
        // });
        let uniqueProfesionales = new Set();

        this.listaProfesional = res
          .map((x) => {
            console.log("x-->0", x);
            let nombreProfesional = null;
            if (x.nombreProfesional != null && x.nombreProfesional.length > 0) {
              let resulta = x.nombreProfesional.split(",");
              const normalize = (nombre) => nombre.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
              nombreProfesional = resulta.length > 1 ? normalize(resulta[1]) + " " + normalize(resulta[0]) : normalize(resulta[0]);

              console.log("x-->1", nombreProfesional);
            }

            // Verificar si el nombreProfesional es único
            if (nombreProfesional && !uniqueProfesionales.has(nombreProfesional)) {
              uniqueProfesionales.add(nombreProfesional);
              // return { nombreProfesional: nombreProfesional, rut: x.idPaciente };
              return { nombreProfesional: nombreProfesional, rut: x.rut };
            }

            return null; // Ignorar duplicados
          })
          .filter((item) => item !== null); // Eliminar valores nulos del resultado

        console.log("x-->3", this.listaProfesional);
        let resSucursales = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaSucursales/",
        });
        this.listaSucursal = resSucursales;
        //  await resSucursales.map((x) => {
        //   return x.sucursal;
        // }, 0);
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },

    updateTables() {
      this.currentPages = [1, 1, 1, 1, 1];
    },
    cadenaMes() {
      let numero = this.mesSeleccionado;
      const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

      // Verificar que el número esté en el rango válido (1-12)
      if (numero < 1 || numero > 12) {
        return "Número inválido. Debe estar entre 1 y 12.";
      }

      // Restar 1 para obtener el índice correcto del mes
      return meses[numero - 1];
    },
    async calculoTotales() {
      try {
        this.totalAgregados = 0;
        this.totalDescuentos = 0;
        //// CALCULANDO AGREGADOS Y DESCUENTOS DE OTROS CONCEPTOS
        let listaAgregados = this.otrosConceptos.filter((x) => x.tipo != "Descuento");
        let totalAgregados = listaAgregados.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          console.log("agregados", valueToAdd);
          return accumulator + valueToAdd;
        }, 0);
        console.log("agregados", totalAgregados);

        // let listaDescuentos = this.otrosConceptos.filter((x) => x.tipo == "Descuento");
        // let totalDescuentos = listaDescuentos.reduce((accumulator, currentItem) => {
        //   const valueToAdd = parseFloat(currentItem.valor);
        //   return accumulator + valueToAdd;
        // }, 0);
        //CALCULANDO AGREGADOS Y DESCUENTOS DE LISTAITEMS
        // let listaAgregadosttos = this.otrosConceptos.filter((x) => x.tipo != "Descuento");
        // let totalAgregadosttos = listaAgregadosttos.reduce((accumulator, currentItem) => {
        //   const valueToAdd = parseFloat(currentItem.valor);
        //   return accumulator + valueToAdd;
        // }, 0);

        let listaDescuentosttos = this.otrosConceptos.filter((x) => x.tipo == "Descuento");
        let totalDescuentosttos = listaDescuentosttos.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valor);
          return accumulator + valueToAdd;
        }, 0);

        this.totalDescuentos = Number(totalDescuentosttos); //+ Number(totalDescuentos);
        this.totalAgregados = Number(totalAgregados); //+ Number(totalAgregados);
        this.totalNetoHonorarios = Number(this.totalAgregados) - Number(this.totalDescuentos);

        ///calculos totales///////////////////////////////////////////

        this.totalTtosRealizados = this.listaItems.reduce((accumulator, currentItem) => {
          // const valueToAdd = parseFloat(currentItem.valorPrestacion);
          // console.log("ESTE ES EL VALOR DE LA PRESTACIÓN", currentItem.valorPrestacion)
          // console.log("ESTE ES EL VALOR DE LA PRESTACIÓN AJUSTADA", currentItem.valorTratamientoAjustado)
          const valueToAdd =
            currentItem.valorTratamientoAjustado != null && currentItem.valorTratamientoAjustado > 0
              ? Number(currentItem.valorTratamientoAjustado) - Number(currentItem.valorTratamientoAjustado) * parseFloat(currentItem.porcentaje)
              : Number(currentItem.valorPrestacion) - Number(currentItem.valorPrestacion) * parseFloat(currentItem.porcentaje);

          return accumulator + valueToAdd;
        }, 0);
        // this.totalTtosRealizados = this.totalTtosRealizados.toFixed(2);
        this.totalCostosMaterial = this.listaItemsMaterial.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valorPrestacion);
          return accumulator + valueToAdd;
        }, 0);

        this.totalCostosRemision = this.listaItemsRemision.reduce((accumulator, currentItem) => {
          const valueToAdd = parseFloat(currentItem.valorPrestacion);
          return accumulator + valueToAdd;
        }, 0);

        // this.totalCostosLaboratorio = this.listaLaboratorio.reduce((accumulator, currentItem) => {
        //   const valueToAdd = parseFloat(currentItem.valor);
        //   return accumulator + valueToAdd;
        // }, 0);

        console.log("this.listaLaboratorio", this.listaLaboratorio);
        this.totalCostosLaboratorio = this.listaLaboratorio.reduce((accumulator, currentItem) => {
          // console.log("ESTE ES EL VALOR DE LA COSTO LABORATORIO", currentItem.valorPrestacion)
          // console.log("ESTE ES EL VALOR DE LA COSTO LABORATORIO AJUSTADO", currentItem.valorPrestacionAjustado)
          // const valueToAdd = parseFloat(currentItem.valorPrestacion);
          //x.valorprestacionajustado != null && x.valorprestacionajustado > 0 ? x.valorprestacionajustado : x.valorPrestacion,

          const valueToAdd =
            currentItem.valorPrestacionAjustado != null && currentItem.valorPrestacionAjustado > 0
              ? parseFloat(currentItem.valorPrestacionAjustado)
              : parseFloat(currentItem.valorPrestacion);

          console.log("this.listaLaboratorio", valueToAdd);
          return accumulator + valueToAdd;
        }, 0);

        /// CALCULANDO TOTAL NETO
        this.totalNetoHonorarios = this.totalNetoHonorarios + this.totalTtosRealizados - this.totalCostosMaterial - this.totalCostosRemision - this.totalCostosLaboratorio;
        this.TotalHonorarios = (this.totalNetoHonorarios * this.PorcentajeSucursal) / 100;
      } catch (error) {
        console.log("error", error);
      }
    },
    async liquidar() {
      try {
        Swal.fire({
          title: "¿Quieres Liquidar?",
          text: "Después de liquidar no podrás modificar datos!",
          icon: "warning",
          showCancelButton: true,
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            this.$isLoading(true);
            //ingresando ttos realizados etc
            console.log(result.value);
            let dataALiquidar = this.listaItems.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.rut,
              PacienteNombre: x.pacienteNombre,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              RutProfesional: this.rutProfesional,
              PorcentajeLiquidacion: this.PorcentajeSucursal,
              // Valor: x.valorPrestacion,
              Valor:
                x.valorTratamientoAjustado != null && x.valorTratamientoAjustado > 0
                  ? Number(x.valorTratamientoAjustado) - Number(x.valorTratamientoAjustado) * parseFloat(x.porcentaje)
                  : Number(x.valorPrestacion) - Number(x.valorPrestacion) * parseFloat(x.porcentaje),
              Tipo: "Agregado",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Ttos. realizados, cancelados y evolucionados",
              Descripcion: x.prestacion,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidar,
            });
            //material especial
            let dataALiquidarMaterial = this.listaItemsMaterial.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.idPaciente,
              PacienteNombre: x.paciente,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valorPrestacion,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos material especial",
              Descripcion: x.prestacionArea,
              RutProfesional: this.rutProfesional,
              PorcentajeLiquidacion: this.PorcentajeSucursal,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarMaterial,
            });

            //Costos de remision
            let dataALiquidarCostosRemision = this.listaItemsRemision.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.idPaciente,
              PacienteNombre: x.paciente,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valorPrestacion,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos de remisión",
              Descripcion: x.prestacionArea,
              RutProfesional: this.rutProfesional,
              PorcentajeLiquidacion: this.PorcentajeSucursal,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarCostosRemision,
            });

            //Costos de laboratorio
            let dataALiquidarCostosDeLaboratorio = this.listaLaboratorio.map((x) => ({
              Id: null,
              ProfesionalNombre: this.profesional,
              Rut: x.rut,
              PacienteNombre: x.pacienteNombre,
              Mes: this.mesSeleccionado,
              Anio: this.anioSeleccionado,
              Valor: x.valorPrestacionAjustado != null && x.valorPrestacionAjustado > 0 ? x.valorPrestacionAjustado : x.valorPrestacion,
              Tipo: "Descuento",
              Fecha: this.$moment(this.Periodo.ultimoDia).format("YYYY-MM-DDTHH:mm"),
              Sucursal: this.sucursal,
              Concepto: "Costos Laboratorio",
              Descripcion: x.prestacion,
              RutProfesional: this.rutProfesional,
              PorcentajeLiquidacion: this.PorcentajeSucursal,
            }));
            await this.$store.dispatch("denti_post", {
              path: "Prestaciones/CrearLiquidacionHonorarios",
              data: dataALiquidarCostosDeLaboratorio,
            });

            this.$isLoading(false);
            await this.VerificaLiquidacionHonorarios();
            Swal.fire("Muy Bien!", "Liquidación generada correctamente!", "success").then(() => {
              // this.initialEvents = [];
              // this.modal = false;
              // this.$router.replace("/calendar");
              // window.location.reload();
            });
          }
        });
      } catch (error) {
        this.$isLoading(false);
        console.log("error", error);
      }
    },
    async verLaboratorioCostospordefecto() {
      // await this.obtenerCostosPorDefecto();
      this.$bvModal.show("LaboratorioCostospordefecto");
    },
    async verCostospordefecto() {
      await this.obtenerCostosPorDefecto();
      this.$bvModal.show("costospordefecto");
    },
    verResumen() {
      this.$bvModal.show("resumen");
    },
    ingresarPaciente() {
      try {
        Swal.fire({
          title: "Ingresa nombre del paciente",
          input: "text",
          inputPlaceholder: "paciente",
          showCancelButton: true,
          confirmButtonText: "Guardar",
          cancelButtonText: "Cancelar",
          didOpen: () => {
            const popup = Swal.getPopup();
            const title = popup.querySelector(".swal2-title");
            const input = popup.querySelector(".swal2-input");
            const confirmButton = popup.querySelector(".swal2-confirm");
            const cancelButton = popup.querySelector(".swal2-cancel");

            // Aplicar estilos en línea
            popup.style.backgroundColor = "#f0f8ff";
            popup.style.borderRadius = "10px";
            popup.style.padding = "2em";

            title.style.color = "#333";
            title.style.fontSize = "1.5rem";
            title.style.fontWeight = "bold";

            input.style.border = "1px solid #ddd";
            input.style.padding = "0.5em";
            input.style.borderRadius = "5px";

            confirmButton.style.backgroundColor = "#085ec5";
            confirmButton.style.color = "white";
            confirmButton.style.padding = "0.5em 1.5em";

            cancelButton.style.backgroundColor = "#f44336";
            cancelButton.style.color = "white";
          },
          inputValidator: (value) => {
            if (!value) {
              return "Debes escribir algo!";
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // Aquí puedes utilizar el texto ingresado por el usuario
            // Swal.fire(`Texto ingresado: ${result.value}`);
            this.pacienteManual = result.value;
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    async saveData() {
      try {
        this.Registro.profesionalNombre = this.profesional;
        // this.Registro.Rut =
        this.Registro.PacienteNombre = this.pacienteManual != "" ? this.pacienteManual : this.paciente.paciente;
        this.Registro.Mes = this.mesSeleccionado;
        this.Registro.Anio = Number(this.anioSeleccionado);
        // this.Registro.Valor =
        // this.Registro.Tipo =

        this.Registro.Fecha = this.$moment.tz(this.Periodo.ultimoDia, "America/Bogota").format("YYYY-MM-DDTHH:mm");
        this.Registro.Sucursal = this.sucursal;
        console.log(this.Registro);
        await this.$store.dispatch("denti_post", {
          path: "Prestaciones/CrearOtros",
          data: this.Registro,
        });
        // this.obtenerOtrosConceptos();
        await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
        this.calculoTotales();

        this.showToast("success", "Datos guardados correctamente");
        this.$bvModal.hide("nuevoServicio");
      } catch (error) {
        this.showToast("Error", "Error, intentalo de nuevo");
        console.log("error", error);
      }
    },
    verModal() {
      this.$bvModal.show("nuevoServicio");
    },
    exportToExcel() {
      // Crear las hojas de datos de cada array con títulos y sumatorias
      const sheet1 = this.createSheetWithSum(this.listaItems, "Prestaciones", "valorPrestacion");
      const sheet2 = this.createSheetWithSum(this.listaItemsMaterial, "Material Especial", "valor");
      const sheet3 = this.createSheetWithSum(this.listaItemsRemision, "Costos de remisión", "valorPrestacion");
      const sheet4 = this.createSheetWithSum(this.listaLaboratorio, "Costos Laboratorio", "valorPrestacion");
      const sheet5 = this.createSheetWithSum(this.otrosConceptos, "Otros conceptos", "valor");

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();

      // Agregar cada hoja de trabajo con el nombre deseado
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet1), "Prestaciones");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet2), "Material Especial");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet3), "Costos de Remisión");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet4), "Costos Laboratorio");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(sheet5), "Otros conceptos");

      // Exportar el archivo Excel
      // XLSX.writeFile(wb, 'datos_separados.xlsx');
      XLSX.writeFile(wb, "Liquidación-" + this.profesional + "-" + this.mesSeleccionado + "-" + this.anioSeleccionado + "-" + this.sucursal + ".xlsx");
    },
    // Función para crear una hoja con sumatoria
    createSheetWithSum(data, title, sumColumn) {
      // Verificar si el array de datos está vacío
      if (!data || data.length === 0) {
        console.warn(`El array ${title} está vacío o no está definido.`);
        return [[title], ["No hay datos disponibles"]]; // Retorna un mensaje si el array está vacío
      }

      const headers = Object.keys(data[0]); // Obtener encabezados de las claves del array
      const sheetData = [];

      // Agregar los encabezados de las columnas
      sheetData.push(headers);

      // Agregar los datos del array
      data.forEach((item) => {
        sheetData.push(headers.map((key) => item[key]));
      });

      // Calcular y agregar la fila de sumatoria
      const sumRow = headers.map((key) => (key === sumColumn ? this.sumColumn(data, key) : ""));
      sheetData.push(["Total", ...sumRow.slice(1)]); // Agregar 'Total' y la sumatoria

      return sheetData;
    },
    // Función para calcular la sumatoria de una columna específica
    sumColumn(data, column) {
      return data.reduce((sum, item) => {
        const value = Number(item[column]); // Convertir a número
        return sum + (isNaN(value) ? 0 : value); // Sumar solo si es un número válido
      }, 0);
    },
    async filtrar() {
      try {
        // if (item == 1) {
        //   this.paciente = null;
        //   this.profesional = null;
        //   this.sucursal = null;
        //   ////////
        //   this.listaLaboratorio = [];
        //   this.listaItemsMaterial = [];
        //   this.otrosConceptos = [];
        //   this.listaItemsRemision = [];
        //   this.existeHonorarios = 0;
        //   this.listaItemsbk = [];

        //   // console.log("filtrando", item, this.nombre);
        //   this.$isLoading(true);
        //   //filtrar x mes y año
        //   let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        //   let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        //   this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        //   await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        this.$isLoading(true);
        // }
        // if (item == 2) {
        //abr 07022025 obtener data del profesional para extraer porcentaje
        this.listaLaboratorio = [];
        this.listaItemsMaterial = [];
        this.otrosConceptos = [];
        this.listaItemsRemision = [];
        this.existeHonorarios = 0;
        // this.updateTables();
        ////////////////////////////////////////////////

        this.paciente = null;
        // this.profesional = null;
        // this.sucursal = null;
        ////////
        this.listaItemsbk = [];
        this.listaItems = [];
        this.totalTtosRealizados = 0;
        let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
        let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
        this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);
        await this.getData(this.Periodo.primerDia, this.Periodo.ultimoDia);

        ///////////////////////////////////////////////
        this.activaTablas = true;
        // console.log(this.paciente);
        if (this.paciente != null && this.profesional == null && this.sucursal == null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal == null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional);
        }
        if (this.paciente != null && this.profesional != null && this.sucursal != null) {
          // console.log(this.paciente);
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
        }

        if (this.paciente == null && this.profesional != null && this.sucursal == null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional);
        }
        if (this.paciente == null && this.profesional != null && this.sucursal != null) {
          // this.listaItems = this.listaItemsbk.filter((x) => x.profesionalNombre == this.profesional && x.sucursalPpto == this.sucursal);
          //problema: no existe rut del profesional en prestaciones
          try {
            this.$isLoading(true);

            //filtrando materiales si ha seleccionado profesional y sucursal
            let mesSeleccionado = new Date(this.anioSeleccionado + "/" + this.mesSeleccionado + "/01").getMonth();
            let anioSeleccionado = this.anioSeleccionado; //new Date().getFullYear();
            this.Periodo = this.obtenerPrimerYUltimoDiaDelMes(anioSeleccionado, mesSeleccionado);

            // material especial

            // obtener otros conceptos
            await this.obtenerOtrosConceptos(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
            await this.VerificaLiquidacionHonorarios();

            this.updateTables();
            this.$forceUpdate();
            this.$isLoading(false);
          } catch (error) {
            this.$isLoading(false);
          }
        }

        if (this.paciente == null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.sucursalPpto == this.sucursal);
        }
        if (this.paciente != null && this.profesional == null && this.sucursal != null) {
          this.listaItems = this.listaItemsbk.filter((x) => x.pacienteNombre == this.paciente && x.sucursalPpto == this.sucursal);
        }
        // }
        await this.calculoTotales();
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async VerificaLiquidacionHonorarios() {
      this.existeHonorarios = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/VerificaLiquidacionHonorarios/" + this.mesSeleccionado + "/" + this.anioSeleccionado + "/" + this.profesional,
      });
      // console.log("otros", res);
    },
    async obtenerOtrosConceptos(primerDia, ultimoDia, sucursal) {
      this.otrosConceptos = [];
      let res = await this.$store.dispatch("denti_get", {
        path: "Prestaciones/ListaOtros/" + primerDia + "/" + ultimoDia + "/" + this.profesional + "/" + sucursal,
      });
      console.log("otros", res);
      res = res.map((x) => {
        return {
          id: x.id,
          rut: x.rut,
          pacienteNombre: x.pacienteNombre,
          concepto: x.concepto,
          descripcion: x.descripcion,
          tipo: x.tipo,
          valor: x.valor,
          excluirPorcentaje: x.excluirPorcentaje,
        };
      });
      this.otrosConceptos = res;
    },
    async obtenerLaboratorio(primerDia, ultimoDia, sucursal) {
      this.listaLaboratorio = [];
      this.listaLaboratorioBk = this.listaLaboratorio;
      let res = await this.$store.dispatch("denti_get", {
        path: "SIE_Laboratorio/ListaLaboratorio/" + primerDia + "/" + ultimoDia + "/" + sucursal + "/" + this.profesional,
      });
      console.log("res dentisalud ", res);
      this.listaLaboratorio = res.length > 0 ? res : [];
      this.listaLaboratorioBk = this.listaLaboratorio;
      await this.aplicarFiltroLaboratorio();
    },
    async obtenerMateriales(primerDia, ultimoDia) {
      this.listaItemsMaterial = [];
      //buscar el idprofesional en this.listaItems

      let res = await this.$store.dispatch("denti_get", {
        path: "VMaterialesEspeciales/ListVMaterialesEspeciales/" + primerDia + "/" + ultimoDia + "/" + this.sucursal + "/" + this.rutProfesional,
      });
      this.listaItemsMaterial = res.length > 0 ? res : [];
      console.log("lista materiales ", this.listaItemsMaterial);
      await this.aplicarFiltroMaterial();
    },
    obtenerPrimerYUltimoDiaDelMes(anio, mes) {
      // El mes en JavaScript es base 0, por lo que enero es 0 y diciembre es 11
      const primerDia = new Date(anio, mes, 1);
      const ultimoDia = new Date(anio, mes + 1, 0); // El día 0 del mes siguiente es el último día del mes actual

      return {
        primerDia: primerDia.toISOString().split("T")[0], // Formato YYYY-MM-DD
        ultimoDia: ultimoDia.toISOString().split("T")[0],
      };
    },

    async getData(primerDia, ultimoDia) {
      try {
        this.listaItemsbk = [];
        this.listaItems = [];
        this.listaLaboratorio = [];
        this.listaLaboratorioBk = [];
        this.$isLoading(true);
        console.log("this.profesional", this.profesional);
        console.log("this.profesional", this.sucursal);
        console.log("this.profesional", this.profesional.nombreProfesional);
        console.log("this.profesional", this.sucursal.sucursal);

        let resBase = await this.$store.dispatch("denti_get", {
          path: "Prestaciones/ListaPrestaciones/" + primerDia + "/" + ultimoDia + "/" + this.profesional + "/" + this.sucursal,
        });

        let res = [...resBase.lista1, ...resBase.lista2];
        // console.log("resultado=", resBase.list);
        console.log("resultado=", res);
        if (!Array.isArray(res)) {
          console.error("Respuesta inválida del servidor:", res);
          this.$isLoading(false);
          await Swal.fire({
            icon: "error",
            title: "Error",
            text: "Hubo un problema al obtener los datos. Por favor, intenta nuevamente.",
          });
          return;
        }

        this.listaItemsbk = res; // Almacenamos los datos originales
        this.listaPacientes = this.listaItemsbk.map((x) => {
          return {
            Rut: x.rut,
            paciente: x.pacienteNombre.toUpperCase().replace(/\s+/g, " ").trim(),
          };
        });
        //eliminando duplicados
        this.listaPacientes = this.listaPacientes.filter((item, index, self) => index === self.findIndex((t) => t.idPaciente === item.idPaciente && t.paciente === item.paciente));
        ///////////
        await this.aplicarFiltro(); // Aplicamos el filtro inicial
        await this.obtenerLaboratorio(primerDia, ultimoDia, this.sucursal);
        await this.obtenerMateriales(primerDia, ultimoDia);

        this.$isLoading(false);
      } catch (error) {
        console.error("Error message:", error.message);
        console.error("Stack trace:", error.stack);

        const stackLines = error.stack.split("\n");
        const errorLine = stackLines[1]; // Generalmente, la línea con el error es la segunda línea
        console.log("Error location:", errorLine.trim());

        console.error("Error al obtener datos:", error);
        this.$isLoading(false);
        await Swal.fire({
          icon: "error",
          title: "Error inesperado",
          text: "Ocurrió un problema al obtener los datos. Por favor, intenta nuevamente.",
        });
      }
    },
    aplicarFiltroMaterial() {
      ////////////////////////////////////
      //excluir costos laboratorio
      const filteredRes = this.listaItemsMaterial.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor !== "excluido";
      });
      //Abr agrego código para mostrar registros excluidos en tabla final
      let itemsExcluidos = this.listaItemsMaterial.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor == "excluido";
      });
      itemsExcluidos = itemsExcluidos.map((item) => {
        let dataReturn = { ...item };
        dataReturn.valorPrestacion = Number(item.valorPrestacion);
        dataReturn.pacienteNombre = item.paciente;
        dataReturn.prestacion = "--";
        dataReturn.fecha = "--";
        return dataReturn;
      });
      console.log("excluidos material", itemsExcluidos);
      let concatenado = this.itemsExcluidos.concat(itemsExcluidos);

      this.itemsExcluidos = concatenado;
      //////////////////codigo viene de filtrar

      let listaItemsMaterial = filteredRes.filter((x) => {
        const normalize = (nombre) => nombre.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
        const nombreProfesional = normalize(this.profesional);
        const nombreItem = normalize(x.profesional);
        const sonIguales = (n1, n2) => {
          const arr1 = n1.split(" ").sort();
          const arr2 = n2.split(" ").sort();
          return JSON.stringify(arr1) === JSON.stringify(arr2);
        };
        return sonIguales(nombreItem, nombreProfesional);
      });
      // obtener costo por defecto material especial y costos de remisión por defecto
      let costoMaterialEspecialPorDefecto = this.CostosPorDefecto.filter((x) => x.categoria == "COSTOS DE MATERIAL ESPECIAL")[0].valorPorDefecto;
      let costoRemisionPorDefecto = this.CostosPorDefecto.filter((x) => x.categoria == "COSTOS DE REMISION")[0].valorPorDefecto;
      // for await (let itm of listaItemsMaterial) {
      //   itm.valor = parseFloat(itm.costoUnitarioProm) * 1.2;
      // }
      listaItemsMaterial = listaItemsMaterial.map((x) => {
        return {
          materialId: x.materialId,
          idPaciente: x.idPaciente,
          paciente: x.paciente,
          prestacionArea: x.prestacionArea,
          valorPrestacion: costoMaterialEspecialPorDefecto, //x.valorPrestacion,
          fecha: x.fecha,
          valorExcluido: x.valorExcluido,
          observacionValorExcluido: x.observacionValorExcluido,
          pacienteNombre: x.paciente,
        };
      });
      this.listaItemsMaterial = listaItemsMaterial;
      console.log("this.listaItemsMaterial", listaItemsMaterial);

      //costos de remision
      this.listaItemsRemision = listaItemsMaterial.map((item) => ({
        ...item,
        valorPrestacion: costoRemisionPorDefecto,
      }));
      ////////////////////////////////////

      this.$forceUpdate();
    },
    aplicarFiltroLaboratorio() {
      ////////////////////////////////////
      //excluir costos laboratorio
      const filteredRes = this.listaLaboratorioBk.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor !== "excluido";
      });
      //Abr agrego código para mostrar registros excluidos en tabla final
      let itemsExcluidos = this.listaLaboratorioBk.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor == "excluido";
      });
      itemsExcluidos = itemsExcluidos.map((item) => {
        let dataReturn = { ...item };
        dataReturn.valorPrestacion = Number(item.valorPrestacion);
        return dataReturn;
      });
      console.log(itemsExcluidos);
      let concatenado = this.itemsExcluidos.concat(itemsExcluidos);

      this.itemsExcluidos = concatenado;
      //////////////////codigo viene de filtrar
      let listaLaboratorio = filteredRes.map((x) => {
        let data = { ...x };
        data.valorPrestacion = Number(x.valorPrestacion);
        data.valorPrestacionAjustado = x.valorPrestacionAjustado != null && x.valorPrestacionAjustado > 0 ? Number(x.valorPrestacionAjustado) : 0;
        return data;
      });

      //  listaLaboratorio = this.listaLaboratorioBk.filter((x) => {
      listaLaboratorio = listaLaboratorio.filter((x) => {
        const normalize2 = (nombre2) => nombre2.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
        const nombreProfesional2 = normalize2(this.profesional);
        const nombreItem2 = normalize2(x.profesionalNombre);
        // Función para comparar dos nombres sin importar el orden
        const sonIguales2 = (n12, n22) => {
          const arr12 = n12.split(" ").sort();
          const arr22 = n22.split(" ").sort();
          return JSON.stringify(arr12) === JSON.stringify(arr22);
        };

        return sonIguales2(nombreItem2, nombreProfesional2);
      });
      this.listaLaboratorio = listaLaboratorio;

      ////////////////////////////////////

      this.$forceUpdate();

      console.log("Datos filtrados laboratorio:", this.listaLaboratorio);
    },
    aplicarFiltro() {
      // Filtramos los datos según la condición de `valorExcluido`
      const filteredRes = this.listaItemsbk.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor !== "excluido";
      });
      //Abr agrego código para mostrar registros excluidos en tabla final
      this.itemsExcluidos = this.listaItemsbk.filter((item) => {
        const valor = item.valorExcluido?.toString().trim().toLowerCase() || "";
        return valor == "excluido";
      });
      this.itemsExcluidos = this.itemsExcluidos.map((item) => {
        let dataReturn = { ...item };
        dataReturn.valorPrestacion = Number(item.valorPrestacion);
        return dataReturn;
      });
      console.log("excluidos", this.itemsExcluidos);
      console.log("filteredRes", filteredRes);
      // Mapeamos los datos filtrados para crear la estructura necesaria
      this.listaItems = filteredRes.map((item) => {
        let valorTotal = 0;
        if (item.sumaPasosPrevios > 0) {
          valorTotal = Number(item.valorPrestacion) + Number(item.sumaPasosPrevios);
        } else {
          valorTotal = item.valorPrestacion;
        }
        return {
          id: item.id,
          rut: item.rut,
          pacienteNombre: item.pacienteNombre,
          prestacion: item.prestacion,
          fechaAccion: item.fFechaAccion,
          valorPrestacion: Number(valorTotal),
          valorTratamientoAjustado: item.valorTratamientoAjustado != null ? Number(item.valorTratamientoAjustado) : 0,
          // valorMenosSubvencion: Number(valorTotal) - Number(valorTotal) * parseFloat(item.porcentaje),
          valorMenosSubvencion:
            item.valorTratamientoAjustado != null && item.valorTratamientoAjustado > 0
              ? Number(item.valorTratamientoAjustado) - Number(item.valorTratamientoAjustado) * parseFloat(item.porcentaje)
              : Number(valorTotal) - Number(valorTotal) * parseFloat(item.porcentaje),
          porcentaje: item.porcentaje,
        };
      });

      console.log("Datos filtrados:", this.listaItems);
    },

    modalTratamiento(id) {
      console.log("EL ID ES EL SIGUIENTE: ", id);
      this.modalTratamientoSeleted = id;
      this.showModalTratamiento = true;
    },

    async submitNewValueTratamiento() {
      try {
        console.log("ESTA ES LA DATA A GUARDAR", this.modalTratamientoSeleted, this.newValueTratamiento.toString());

        const response = await this.$store.dispatch("denti_put", {
          path: "Prestaciones/valor-ajustado-tratamientoV2/" + this.modalTratamientoSeleted + "/" + this.Periodo.primerDia + "/" + this.Periodo.ultimoDia,
          data: this.newValueTratamiento.toString(),
        });
        if (response.error) {
          await Swal.fire({
            icon: "error",
            title: "Error",
            text: response.error,
          });
        } else {
          this.showModalTratamiento = false;

          const valorActualizado = this.listaItems.find((i) => i.id == this.modalTratamientoSeleted);

          const valorNumerico = Number(this.newValueTratamiento);
          if (valorActualizado) {
            valorActualizado.valorTratamientoAjustado = valorNumerico;
            console.log("Valor actualizado correctamente:", valorActualizado);

            this.calculoTotales();
          } else {
            console.log("No se encontró el objeto con el ID especificado.");
          }

          await Swal.fire({
            icon: "success",
            title: "¡Éxito!",
            text: "El valor se actualizó correctamente.",
          });
          this.newValueTratamiento = "";
        }
      } catch (error) {
        // Manejamos errores inesperados
        console.error("Error al guardar el valor:", error);
        await Swal.fire({
          icon: "error",
          title: "Error inesperado",
          text: "Ocurrió un problema al guardar el valor. Por favor, intenta nuevamente.",
        });
      }
    },

    modalLaboratorio(id) {
      console.log("EL ID ES EL SIGUIENTE: ", id);
      this.modalLaboratorioSeleted = id;
      this.showModalLaboratorio = true;
    },
    async submitNewValueLab() {
      try {
        console.log("ESTA ES LA DATA A GUARDAR", this.modalLaboratorioSeleted, this.newValueLaboratorio.toString());

        const response = await this.$store.dispatch("denti_put", {
          path: "Prestaciones/valor-ajustado/" + this.modalLaboratorioSeleted,
          data: this.newValueLaboratorio.toString(),
        });
        if (response.error) {
          await Swal.fire({
            icon: "error",
            title: "Error",
            text: response.error,
          });
        } else {
          this.showModalLaboratorio = false;
          this.newValueLaboratorio = "";
          await Swal.fire({
            icon: "success",
            title: "¡Éxito!",
            text: "El valor se actualizó correctamente.",
          });

          await this.obtenerLaboratorio(this.Periodo.primerDia, this.Periodo.ultimoDia, this.sucursal);
          let listaLaboratorio = this.listaLaboratorioBk.map((x) => {
            let data = { ...x };
            data.valorPrestacion = Number(x.valorPrestacion);
            data.valorPrestacionAjustado = x.valorPrestacionAjustado == null ? "No ajustado" : Number(x.valorPrestacionAjustado);
            return data;
          });
          //  listaLaboratorio = this.listaLaboratorioBk.filter((x) => {
          listaLaboratorio = listaLaboratorio.filter((x) => {
            const normalize2 = (nombre2) => nombre2.trim().toLowerCase().replace(/\s+/g, " ").replace(",", "");
            const nombreProfesional2 = normalize2(this.profesional);
            const nombreItem2 = normalize2(x.profesionalNombre);
            const sonIguales2 = (n12, n22) => {
              const arr12 = n12.split(" ").sort();
              const arr22 = n22.split(" ").sort();
              return JSON.stringify(arr12) === JSON.stringify(arr22);
            };

            return sonIguales2(nombreItem2, nombreProfesional2);
          });
          this.calculoTotales();
          this.listaLaboratorio = listaLaboratorio;
        }
      } catch (error) {
        // Manejamos errores inesperados
        console.error("Error al guardar el valor:", error);
        await Swal.fire({
          icon: "error",
          title: "Error inesperado",
          text: "Ocurrió un problema al guardar el valor. Por favor, intenta nuevamente.",
        });
      }
    },

    modalValueExcluido(id) {
      console.log("EL ID ES EL SIGUIENTE: ", id);
      this.modalValorExcluidoSelected = id;
      this.showModalValorExcluido = true;
    },
    async submitNewValueExcluido() {
      try {
        console.log("ESTA ES LA DATA A GUARDAR", this.modalValorExcluidoSelected, this.newValueExcluido, this.observacionValorExcluido);

        const response = await this.$store.dispatch("denti_put", {
          path: `Prestaciones/valor-excluidoV2/${this.modalValorExcluidoSelected}?nuevoValorExcluido=${encodeURIComponent(
            this.newValueExcluido
          )}&observacionNuevoValorExcluido=${encodeURIComponent(this.observacionValorExcluido)}`,
          data: this.newValueLaboratorio.toString(),
        });

        if (response.error) {
          await Swal.fire({
            icon: "error",
            title: "Error",
            text: response.error,
          });
        } else {
          this.showModalValorExcluido = false;
          this.observacionValorExcluido = "";
          await Swal.fire({
            icon: "success",
            title: "¡Éxito!",
            text: "El valor se actualizó correctamente.",
          });

          this.listaItems = this.listaItems.filter((item) => item.id !== this.modalValorExcluidoSelected);
          console.log("Lista actualizada:", this.listaItems);
        }
      } catch (error) {
        console.error("Error al guardar el valor:", error);
        await Swal.fire({
          icon: "error",
          title: "Error inesperado",
          text: "Ocurrió un problema al guardar el valor. Por favor, intenta nuevamente.",
        });
      }
    },
    watch: {
      listaItemsbk: {
        handler(newVal) {
          console.log("Cambios detectados en listaItemsbk:", newVal);
          this.aplicarFiltro();
        },
        deep: true,
      },
    },
  },
};
</script>

<style scoped>
.custom-popup {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 1em;
  font-size: 1rem !important;
}

.custom-title {
  color: #333;
  font-size: 0.5rem !important;
  font-weight: bold;
}

.custom-input {
  border: 1px solid #ddd;
  padding: 0.5em;
  border-radius: 5px;
}

.custom-confirm-button {
  background-color: #4caf50;
  color: white;
  padding: 0.5em 1.5em;
}

.custom-cancel-button {
  background-color: #f44336;
  color: white;
}
</style>
